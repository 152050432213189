import React from 'react'

import demo1 from '../../images/demo/1.png'
import demo2 from '../../images/demo/2.png'
import demo3 from '../../images/demo/3.png'
import demo4 from '../../images/demo/4.png'
import demo5 from '../../images/demo/6.png'
import demo6 from '../../images/demo/7.png'
import demo7 from '../../images/demo/8.jpg'

const Demo = () =>{
    return(
        <section className="wpo-demo-section section-padding" id="demo">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col col-lg-4">
                <div className="wpo-section-title">
                    <h2>Unique Home Pages</h2>
                    <p>The Ultimate Niche WordPress Theme for the Weddings & Wedding Planner</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-xs-12">
                <div className="wpo-demo-grids clearfix">
                    <div className="grid" >
                        <div className="inner">
                            <a href="https://wpocean.com/html/tf/feelings/index.html" target="_blank"><img src={demo1} alt=""/></a>
                        </div>
                        <h3>Main Home</h3>
                    </div>
                    <div className="grid">
                        <div className="inner">
                            <a href="https://wpocean.com/html/tf/feelings/index-2.html" target="_blank"><img src={demo2} alt=""/></a>
                        </div>
                        <h3>Announcement Home 1</h3>
                    </div>
                    <div className="grid">
                        <div className="inner">
                            <a href="https://wpocean.com/html/tf/feelings/index-3.html" target="_blank"><img src={demo3} alt=""/></a>
                        </div>
                        <h3>Announcement Home 2</h3>
                    </div>
                    <div className="grid">
                        <div className="inner">
                            <a href="https://wpocean.com/html/tf/feelings/index-4.html" target="_blank"><img src={demo4} alt=""/></a>
                        </div>
                        <h3>Wedding Planner</h3>
                    </div>
                    <div className="grid">
                        <div className="inner">
                            <a href="https://wpocean.com/html/tf/feelings/index-5.html" target="_blank"><img src={demo5} alt=""/></a>
                        </div>
                        <h3>Shop Home</h3>
                    </div>
                    <div className="grid">
                        <div className="inner-s2">
                            <a href="https://wpocean.com/html/tf/feelings/index-6.html" target="_blank"><img src={demo6} alt=""/></a>
                        </div>
                        <h3>Home Invitation</h3>
                    </div>
                    <div className="grid">
                        <div className="inner-s">
                            <img src={demo7} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    )
}

export default Demo;