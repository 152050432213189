import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import Demo from '../../components/demo'
import Choose from '../../components/Choose'
import Pages from '../../components/Pages'
import Features from '../../components/Features'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <Hero/>
           <Demo/>
           <Choose/>
           <Pages/>
           <Features/>
           <Footer/>
           <Scrollbar/>
       </div>
    )
}

export default Homepage;